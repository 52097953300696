@keyframes clockwise {
  0% {
    transform: rotate(0deg) translate(-165px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate(-165px) rotate(-360deg);
  }
}

@keyframes anti-clockwise {
  0% {
    transform: rotate(0deg) translate(165px) rotate(0deg);
  }

  100% {
    transform: rotate(-360deg) translate(165px) rotate(360deg);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotating-diagonal {
  0% {
    transform: translate(-300px, 150px) rotate(0deg);
  }

  100% {
    transform: translate(300px, -150px) rotate(180deg);
  }
}

.animation--clockwise {
  animation: clockwise 30s linear infinite normal;
}

.animation--anti-clockwise {
  animation: anti-clockwise 30s linear infinite normal;
}

.animation--rotating {
  animation: rotating 15s linear infinite normal;
}

.animation--rotating-diagonal {
  animation: rotating-diagonal 15s linear infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes jump {
  0% {
    top: 0;
  }

  50% {
    top: 50px;
  }

  100% {
    top: 0;
  }
}

.animation {
  transform-style: preserve-3d;
}

.typed-cursor {
  opacity: 1;
  animation: blink 0.7s infinite;
}

@keyframes floating {
  from {
    transform: translate(0, 0);
  }

  65% {
    transform: translate(0, 10px);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes floating {
  from {
    transform: translate(0, 0);
  }

  65% {
    transform: translate(0, 10px);
  }

  to {
    transform: translate(0, 0);
  }
}

.floating {
  filter: none;
  animation: floating 4s ease-in-out infinite;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bubble-v {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-640px);
  }
}

@keyframes bubble-v-left {
  0% {
    margin-bottom: 0;
    opacity: 0.1;
  }

  20% {
    opacity: 1;
  }

  100% {
    left: 0;
    margin-bottom: 640px;
    opacity: 1;
  }
}

@keyframes bubble-v-right {
  0% {
    margin-bottom: 0;
    opacity: 0.1;
  }

  20% {
    opacity: 1;
  }

  100% {
    left: 100%;
    margin-bottom: 640px;
    opacity: 1;
  }
}

@keyframes shake-v {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 20px;
  }
}
