/* ------------------------------------*\
  #THEME LIGHT OVERRIDES
\*------------------------------------ */

.theme--light {
  .custom-elevation {
    box-shadow: rgb(0 0 0 / 8%) 0 4px 12px !important;
  }

  &.v-date-picker-table {
    &.v-date-picker-table--date {
      // Add border to today's date IF it's also selected
      .v-btn.v-date-picker-table__current.v-btn--active {
        border: 1px $blue solid !important;
      }

      // Changed styling of selected dates
      .v-btn.v-btn--active {
        color: $blue !important;
        background: #e1f5fe !important;
      }
    }
  }

  // To override the soft grey styling on card text. This should make text
  // more legible
  &.v-card {
    > .v-card__text {
      color: rgb(0 0 0 / 87%);
    }
  }
}

/* ------------------------------------*\
  #THEME DARK OVERRIDES
\*------------------------------------ */

.theme--dark {
  .custom-elevation {
    box-shadow: rgb(125 125 125 / 8%) 0 4px 12px !important;
  }

  &.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #6161612a !important;
  }

  .v-date-picker-header__value button {
    font-weight: 400 !important;
  }

  &.v-picker__body {
    background: none !important;

    .v-date-picker-table {
      .v-btn.v-btn--active.blue {
        color: #000029;
      }
    }
  }

  .border {
    &.border--white {
      border-color: #303030 !important;
    }
  }

  // To override the soft grey styling on card text. This should make text
  // more legible
  &.v-card {
    > .v-card__text {
      color: $white;
    }
  }
}

/* ------------------------------------*\
  #BORDER
\*------------------------------------ */
.border {
  border-color: rgb(0 0 0 / 8%);
  border-style: solid;
  border-width: 1px;

  $border-styles: (
    none,
    hidden,
    dotted,
    dashed,
    solid,
    double,
    groove,
    ridge,
    inset,
    outset,
    initial,
    inherit
  );

  @each $name in $border-styles {
    &.border--#{$name} {
      border-style: $name !important;
    }
  }

  $border-width: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20);

  @each $width in $border-width {
    &.border--width-#{$width} {
      border-width: unquote('#{$width}px');
    }
  }

  &.border--blue {
    border-color: $blue !important;
  }

  &.border--indigo {
    border-color: unquote('#{$indigo}de') !important;
  }

  &.border--light {
    border-color: rgb(0 0 0 / 10%) !important;
  }

  &.border--grey {
    border-color: rgb(0 0 0 / 50%) !important;
  }

  &.border--white {
    border-color: #fff !important;
  }
}

$fade-colours: (
  'blue': $blue,
  'success': $green,
);

@each $name, $colour in $fade-colours {
  .#{$name}--fade {
    background: rgba($colour, 0.2) !important;
  }
}

.theme--light.v-chip.chip--fade:hover::before,
.theme--light.v-chip.chip--fade::before {
  opacity: 0.12 !important;
}

/* ------------------------------------*\
  #OPACITY
\*------------------------------------ */
$opacity-levels: (
  '0': 0,
  '1': 0.1,
  '2': 0.2,
  '3': 0.3,
  '4': 0.4,
  '5': 0.5,
  '6': 0.6,
  '7': 0.7,
  '8': 0.8,
  '9': 0.9,
  '10': 1,
  'none': 0,
  'half': 0.5,
  'full': 1,
);

@each $name, $level in $opacity-levels {
  .opacity-#{$name} {
    opacity: $level;
  }
}

.date-stripe--active {
  background: unquote('#{$color-body-bg-secondary}de') !important;
}

/* ------------------------------------*\
  #SIZING UTILS
\*------------------------------------ */

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-fit-content {
  width: fit-content !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

/* ------------------------------------*\
  #CURSORS
\*------------------------------------ */
$cursors: (
  'pointer': 'pointer',
  'not-allowed': 'not-allowed',
  'disabled': 'not-allowed',
  'default': 'default',
  'auto': 'auto',
  'help': 'help',
  'wait': 'wait',
  'zoom-in': 'zoom-in',
  'grab': 'grab',
);

@each $name, $opt in $cursors {
  .cursor-#{$name} {
    cursor: #{$opt} !important;
  }
}

/* ------------------------------------*\
  #TWEAKS
\*------------------------------------ */
.custom-elevation {
  overflow: hidden; // Clips effects to rounded edge
}

/* ------------------------------------*\
  #WHITE-SPACE
\*------------------------------------ */

/* Further reading: https://developer.mozilla.org/en-US/docs/Web/CSS/white-space */
$whiteline-opts: (
  'normal': 'normal',
  'nowrap': 'nowrap',
  'pre': 'pre',
  'pre-line': 'pre-line',
  'pre-wrap': 'pre-wrap',
  'break-spaces': 'break-spaces',
);

@each $name, $opt in $whiteline-opts {
  .ws-#{$name} {
    white-space: #{$opt} !important;
  }
}

/* ------------------------------------*\
  #DEBUG-BAR
\*------------------------------------ */
.debug-bar__mono {
  font-family: consolas, 'Azeret Mono', monospace !important;
  font-size: 0.75rem !important;
  font-weight: 300 !important;

  &.debug-bar__mono--mobile {
    font-size: 0.6rem !important;
  }
}

/* ------------------------------------*\
  #HIGHLIGHT
\*------------------------------------ */
.search-results__mark {
  background: lighten($blue, 35%);
}

::selection {
  color: white;

  /* Your CSS styles to apply when
    user selects content in the HTML */
  background-color: lighten($indigo, 35%);
}

/* ------------------------------------*\
  #BUTTONS
\*------------------------------------ */

.lc {
  text-transform: capitalize !important;
  letter-spacing: 0.15px !important;
}

.lc-btn {
  max-width: fit-content !important;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 0.85rem !important;
  color: #3c4043 !important;
  text-transform: capitalize !important;
  letter-spacing: 0.15px !important;
  border-color: #dadce0 !important;

  &.lc-rounded {
    padding: 10px 24px !important;
  }

  &.lc-pill {
    padding: 8px 16px !important;
  }

  &.lc-dark {
    color: #fff !important;
  }
}

/* ------------------------------------*\
  #APP SHAPES
\*------------------------------------ */

.booking-guest-footer__shapes__blue-donut {
  position: absolute;
  top: 30px;
  right: 70px;
  bottom: 0%;
  left: auto;
}

@media screen and (max-width: 479px) {
  .booking-guest-footer__shapes__blue-donut {
    max-width: 80%;
  }
}

@media screen and (max-width: 991px) {
  .booking-guest-footer__shapes__blue-donut {
    max-width: 60%;
  }
}

.booking-guest-footer__shapes__yellow-square {
  position: absolute;
  top: 30px;
  right: 450px;
  bottom: 0%;
  left: auto;
  transform: rotate(45deg);
}

/* ------------------------------------*\
  #ROUTER-LINK
\*------------------------------------ */

.router-link--disable-active-styling {
  &.v-btn--active:not(:hover)::before,
  &.v-list-item--active:not(:hover)::before {
    opacity: 0 !important;
  }

  &.v-btn--active::before,
  &.v-list-item--active::before {
    opacity: 0.05 !important;
  }
}

/* ------------------------------------*\
  #INPUT
\*------------------------------------ */

/**
 * Fixes issue in chrome of the calendar icon being difficult to see on input type "date"
 * when viewing in dark mode. The below fix allows the icon to inherit the colour of the
 * parent. Which will switch based on light/dark mode.
 *
 * Sourced from: https://stackoverflow.com/a/67376605 at 04/04/2023 at 4:04 PM
 */
input[type='date']::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}

/* ------------------------------------*\
 #V-CARD
\*------------------------------------ */

// Removes background colour change on focus
.disabled-no-focus {
  &.v-card--link:focus::before,
  &.v-list-item--link:focus::before,
  &.v-list-item:hover::before {
    opacity: 0;
  }
}
