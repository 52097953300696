@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































.debug-log__mono {
  font-family: 'consolas', 'Azeret Mono', monospace !important;
  font-weight: 300 !important;
}
