@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














































































































































@import '@/styles/animations';
@import '@/styles/login';
@import '@/styles/shapes';

.z-index-1 {
  z-index: 1;
}

.errorHeader {
  position: relative;
  text-align: center;

  .text {
    position: relative;
    z-index: 1;
    padding: 20px 20px 8px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 4rem;
    font-weight: bolder;
  }

  &.errorHeader--sm {
    .text {
      font-size: 3.5rem !important;
      font-weight: bolder;
    }
  }

  .bgHighlight {
    position: absolute;
    top: 0;
    bottom: 0.3em;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: -10px;
    background-color: #f5b51a;
  }
}
