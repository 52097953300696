@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';











































// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';

// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Design variables and utilities from src/styles.
@import '@styles';

// Global styles
@import '@/styles/global';

// ===
// Vendor
// ===
.toasted {
  size: 0.9rem;
  font-family: $content-font-family;
}

#nprogress .bar {
  background: $color-link-text;
}
