$heading-font-family: 'Poppins', -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
  'Helvetica', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$heading-font-weight: 600;

$content-font-family: 'Lato', -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
  'Helvetica', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$content-font-weight: 400;

$monospace-font-family: 'Azeret Mono', monospace;
$monospace-font-weight: 300;

%font-heading {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $color-heading-text;
}

%font-content {
  font-family: $content-font-family;
  font-weight: $content-font-weight;
  color: $color-text;
}

.font-monospace {
  font-family: $monospace-font-family;
  font-weight: $monospace-font-weight;
  color: $color-text;
}
