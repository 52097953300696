@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
















































.title {
  text-align: center;
}
