@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



































































.wordCarousel {
  div {
    height: 45px;
    overflow: hidden;

    ul {
      box-shadow: inset 0 0 30px #fafafa, 0 0 30px #fafafa;

      &.dark {
        box-shadow: inset 0 0 30px #121212, 0 0 30px #121212;
      }

      li {
        display: block;
        height: 45px;
      }
    }
  }
}

.flip-text {
  padding-left: 0 !important;
  animation: flip-text 14s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}

@keyframes flip-text {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: -45px;
  }

  20% {
    margin-top: -90px;
  }

  30% {
    margin-top: -135px;
  }

  40% {
    margin-top: -180px;
  }

  50% {
    margin-top: -225px;
  }

  60% {
    margin-top: -180px;
  }

  70% {
    margin-top: -135px;
  }

  80% {
    margin-top: -90px;
  }

  90% {
    margin-top: -45px;
  }

  100% {
    margin-top: 0;
  }
}
