@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






























.r2w_bg {
  position: relative;
  background-image: url('https://anzukmedia.blob.core.windows.net/assets/r2w/img/bg/bg_min.jpg');
  background-image: linear-gradient(
      172deg,
      rgb(61 48 136 / 83%) 37%,
      rgb(61 48 136 / 83%) 0,
      #3d3088 100%,
      #3d3088
    ),
    url('https://anzukmedia.blob.core.windows.net/assets/r2w/img/bg/bg_min.jpg');
  background-position: center;
  background-size: cover;
  opacity: 1;
}
