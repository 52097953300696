// CONTENT
$color-body-bg: #f9f7f5;
$color-body-bg-secondary: #f2f5fe;
$color-text: #444;
$color-heading-text: #35495e;

// LINKS
$color-link-text: #39a275;
$color-link-text-active: $color-text;

// INPUTS
$color-input-border: lighten($color-heading-text, 50%);

// BUTTONS
$color-button-bg: $color-link-text;
$color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
$color-button-text: white;

// COLOURS
// Trying to stick to the Material Design semantic colour naming scheme:
// https://m2.material.io/design/color/the-color-system.html#color-usage-and-palettes
//
// Following Vuetify's colours: https://vuetifyjs.com/en/styles/colors/#material-colors
//
// Use this to assist with generating colour scales for custom colours:
// https://hihayk.github.io/scale/#4/5/50/99/-51/0/7/14/00acef/0/172/239/black
//
// Good article on semantic colour naming:
// https://uxdesign.cc/how-should-you-name-your-colors-in-a-design-system-3086513476df

$dark-blue: #3f51b5;
$color-dark-blue-900: #1a237e;
$color-dark-blue-800: #283593;
$color-dark-blue-700: #303f9f;
$color-dark-blue-600: #3949ab;
$color-dark-blue-500: $dark-blue;
$color-dark-blue-400: #5c6bc0;
$color-dark-blue-300: #7986cb;
$color-dark-blue-200: #9fa8da;
$color-dark-blue-100: #c5cae9;
$color-dark-blue-50: #e8eaf6;
$blue: #00acef;
$color-blue-900: #090080;
$color-blue-800: #00069d;
$color-blue-700: #0032ba;
$color-blue-600: #006ad5;
$color-blue-500: $blue;
$color-blue-400: #33bcf2;
$color-blue-300: #66cdf5;
$color-blue-200: #98ddf8;
$color-blue-100: #caeefb;
$color-blue-50: #fdfeff;
$indigo: #3d3088;
$color-indigo-900: #471547;
$color-indigo-800: #4b1b58;
$color-indigo-700: #4a2268;
$color-indigo-600: #452978;
$color-indigo-500: $indigo;
$color-indigo-400: #4a4d99;
$color-indigo-300: #6575aa;
$color-indigo-200: #8098ba;
$color-indigo-100: #9cb7ca;
$color-indigo-50: #b8d2da;
$red: #f44336;
$color-red-900: #b71c1c;
$color-red-800: #c62828;
$color-red-700: #d32f2f;
$color-red-600: #e53935;
$color-red-500: $red;
$color-red-400: #ef5350;
$color-red-300: #e57373;
$color-red-200: #ef9a9a;
$color-red-100: #ffcdd2;
$color-red-50: #ffebee;
$orange: #ff9800;
$color-orange-900: #e65100;
$color-orange-800: #ef6c00;
$color-orange-700: #f57c00;
$color-orange-600: #fb8c00;
$color-orange-500: $orange;
$color-orange-400: #ffa726;
$color-orange-300: #ffb74d;
$color-orange-200: #ffcc80;
$color-orange-100: #ffe0b2;
$color-orange-50: #fff3e0;
$yellow: #ffeb3b;
$color-yellow-900: #f57f17;
$color-yellow-800: #f9a825;
$color-yellow-700: #fbc02d;
$color-yellow-600: #fdd835;
$color-yellow-500: $yellow;
$color-yellow-400: #ffee58;
$color-yellow-300: #fff176;
$color-yellow-200: #fff59d;
$color-yellow-100: #fff9c4;
$color-yellow-50: #fffde7;
$green: #4caf50;
$color-green-900: #1b5e20;
$color-green-800: #2e7d32;
$color-green-700: #388e3c;
$color-green-600: #43a047;
$color-green-500: $green;
$color-green-400: #66bb6a;
$color-green-300: #81c784;
$color-green-200: #a5d6a7;
$color-green-100: #c8e6c9;
$color-green-50: #e8f5e9;
$teal: #009688;
$color-teal-900: #004d40;
$color-teal-800: #00695c;
$color-teal-700: #00796b;
$color-teal-600: #00897b;
$color-teal-500: $teal;
$color-teal-400: #26a69a;
$color-teal-300: #4db6ac;
$color-teal-200: #80cbc4;
$color-teal-100: #b2dfdb;
$color-teal-50: #e0f2f1;
$cyan: #00bcd4;
$color-cyan-900: #006064;
$color-cyan-800: #00838f;
$color-cyan-700: #0097a7;
$color-cyan-600: #00acc1;
$color-cyan-500: $cyan;
$color-cyan-400: #26c6da;
$color-cyan-300: #4dd0e1;
$color-cyan-200: #80deea;
$color-cyan-100: #b2ebf2;
$color-cyan-50: #e0f7fa;
$light-blue: #03a9f4;
$color-light-blue-900: #01579b;
$color-light-blue-800: #0277bd;
$color-light-blue-700: #0288d1;
$color-light-blue-600: #039be5;
$color-light-blue-500: $light-blue;
$color-light-blue-400: #29b6f6;
$color-light-blue-300: #4fc3f7;
$color-light-blue-200: #81d4fa;
$color-light-blue-100: #b3e5fc;
$color-light-blue-50: #e1f5fe;
$off-white: #f7f8fe;
$white: #fff;

// Context Colours
$success: $color-green-500;
$color-success: $color-green-500;
$warning: #e6961f;
$color-warning: $color-orange-500;
$error: $color-red-500;
$color-error: $color-red-500;
