// Customise Vuetify Sass Variables
// See https://vuetifyjs.com/en/features/sass-variables/#vue-cli-install

$body-font-family: 'Poppins', -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
  'Helvetica', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$heading-font-family: 'Poppins', -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
  'Helvetica', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$btn-text-transform: capitalize;
$btn-letter-spacing: 0;
$material-light: (
  'background': #fafafa,
);
$date-picker-table-date-font-weight: 600;
$date-picker-title-date-font-weight: 500;

.v-btn {
  font-family: $heading-font-family;
}

.v-tab {
  text-transform: none;
  letter-spacing: normal;
}

.v-navigation-drawer {
  font-family: $heading-font-family;

  .v-list-item__title,
  .v-list-item__subtitle,
  .v-list-item__content {
    font-family: $heading-font-family !important;
  }
}

.v-avatar {
  font-family: $heading-font-family !important;

  span,
  div,
  p {
    font-family: $heading-font-family !important;
  }
}
