.shapes-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.animation-shape {
  position: absolute;
}

.animation-shape.shape-triangle {
  top: 50%;
  left: 50%;
}

.animation-shape.shape-triangle div {
  position: relative;
  text-align: left;
  background-color: #fff;
  opacity: 0.1;
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}

.animation-shape.shape-triangle div::before,
.animation-shape.shape-triangle div::after {
  position: absolute;
  content: '';
  background-color: inherit;
}

.animation-shape.shape-triangle div,
.animation-shape.shape-triangle div::before,
.animation-shape.shape-triangle div::after {
  width: 15px;
  height: 15px;
  border-top-right-radius: 30%;
}

.animation-shape.shape-triangle div::before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}

.animation-shape.shape-triangle div::after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.animation-shape.shape-ring {
  top: 75%;
  left: 25%;
}

.animation-shape.shape-ring div {
  width: 20px;
  height: 20px;
  border: 5px solid #f5b51a;
  border-radius: 50%;
}

.animation-shape.shape-circle {
  top: 20%;
  left: 50%;
}

.animation-shape.shape-circle div {
  width: 15px;
  height: 15px;
  margin: 0;
  background: rgb(237 244 248 / 30%);
  border-radius: 50%;
}

.animation-shape.shape-diamond {
  top: 35%;
  left: 25%;
}

.animation-shape.shape-diamond div {
  position: relative;
  top: -10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #0aceff;
  opacity: 0.5;
}

.animation-shape.shape-diamond div::after {
  position: absolute;
  top: 10px;
  left: -10px;
  width: 0;
  height: 0;
  content: '';
  border: 10px solid transparent;
  border-top-color: #0aceff;
}

.static-shape {
  position: absolute;
}

.static-shape.shape-ring-1 {
  top: 15%;
  left: 0;
  width: 80px;
  height: 80px;
  border: 15px solid rgb(255 255 255 / 50%);
  border-radius: 50%;
  transform: translateX(-50%);
  transform: translateX(-50%);
}

.static-shape.shape-ring-2 {
  top: 5%;
  right: 0;
  width: 120px;
  height: 120px;
  border: 15px solid rgb(255 255 255 / 50%);
  border-radius: 50%;
  transform: translateX(50%);
  transform: translateX(50%);
}

.static-shape.shape-ring-3 {
  top: 5%;
  right: 0;
  width: 120px;
  height: 120px;
  border: 15px solid rgb(255 255 255 / 15%);
  border-radius: 50%;
  transform: translateX(50%);
  transform: translateX(50%);
}

.static-shape.shape-circle-1 {
  top: 22%;
  left: 22%;
  width: 120px;
  height: 120px;
  margin: 0;
  background: rgb(255 255 255 / 9%);
  border-radius: 50%;
}

.static-shape.pattern-dots-1 {
  top: 0;
  right: 0;
  width: 250px;
  height: 50%;
  background-color: transparent;
  background-image: radial-gradient(
    rgb(255 255 255 / 10%) 3px,
    transparent 3px
  );
  background-size: 30px 30px;
}

.static-shape.pattern-dots-2 {
  bottom: 0;
  left: 0;
  width: 150px;
  height: 20%;
  background-color: transparent;
  background-image: radial-gradient(
    rgb(255 255 255 / 10%) 3px,
    transparent 3px
  );
  background-size: 30px 30px;
}

.static-shape.ghost-shape {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 120px;
  opacity: 0.3;
}

.static-shape.ghost-shape-1 {
  top: 0;
  max-height: 560px;
  background-color: #e56357;
  box-shadow: none;
  transform: translate3d(30%, -93%, 0) skewX(35deg) rotate(-12deg);
}

.static-shape.ghost-shape-2 {
  top: 80%;
  left: 5%;
  width: 22px;
  height: 22px;
  background-color: rgb(255 255 255 / 5%);
  box-shadow: none;
  transform: rotate(-32deg);
}

.static-shape.ghost-shape-3 {
  top: 40%;
  left: 30%;
  width: 180px;
  height: 180px;
  background-color: rgb(255 255 255 / 5%);
  box-shadow: none;
  transform: rotate(-52deg);
}
