.logo {
  position: absolute;
  top: 0;
  display: inline-block;
  margin: 20px 40px;

  &.service-alert-is-displayed {
    top: 80px;
  }
}

.service-status-alert__container {
  position: absolute;
  top: 0;
  left: 0;
}

.word-break-norm {
  word-break: normal;
}

.login-form-title {
  font-size: 2.2rem !important;
  font-weight: bold !important;

  &.sm {
    font-size: 1.8rem !important;
  }
}

.account-setup-form-title {
  margin-top: 40px !important;
}

.slide-1 {
  position: relative;
  background-image: url('https://anzukmedia.blob.core.windows.net/assets/r2w/img/bg/bg_min.jpg');
  background-image: linear-gradient(
      172deg,
      rgb(61 48 136 / 83%) 37%,
      rgb(61 48 136 / 83%) 0,
      #3d3088 100%,
      #3d3088
    ),
    url('https://anzukmedia.blob.core.windows.net/assets/r2w/img/bg/bg_min.jpg');
  background-position: center;
  background-size: cover;
  opacity: 1;
}

.r2bheader {
  position: relative;

  .text {
    position: relative;
    z-index: 2;
    padding: 20px 20px 8px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 4rem;
    font-weight: bold;
  }

  .bgHighlight {
    position: absolute;
    top: 0;
    bottom: 0.3em;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: -10px;
    background-color: #f5b51a;
  }
}
